import { eventQBus } from "../utils/EventQBus";
import { OnTileLoaded } from "../types/Tile";
import { SimilarProductsButtonAnimation } from "./SimilarProductsButtonAnimation";

export enum CLASS {
  SIMILAR_PRODUCTS_CONTAINER = "js_reptile_tile__similarProducts",
  SIMILAR_PRODUCTS_BUTTON = "js_reptile_tile__similarProductsButton",
}

export class SimilarProductsButton {
  private animation: SimilarProductsButtonAnimation | null;

  private constructor(
    public target: HTMLElement,
    private variationId: string,
  ) {
    this.container?.addEventListener("click", (event) => this.onClick(event));
    this.animation = SimilarProductsButtonAnimation.create(this.container);
  }

  static create(target: HTMLElement, variationId: string) {
    return new SimilarProductsButton(target, variationId);
  }

  static createAll() {
    Array.from(document.getElementsByClassName("find_tile")).forEach((elem: Element) => {
      if (elem instanceof HTMLElement && elem.dataset.variationId) {
        SimilarProductsButton.create(elem, elem.dataset.variationId);
      }
    });
  }

  close() {
    this.container?.removeEventListener("click", (event) => this.onClick(event));
    this.animation?.close();
  }

  get container(): HTMLDivElement {
    const container = this.target.querySelector(`.${CLASS.SIMILAR_PRODUCTS_CONTAINER}`) as HTMLDivElement;
    if (!container) return document.createElement("div");
    return container;
  }

  private onClick(eventClick: MouseEvent) {
    eventClick.preventDefault();
    eventQBus.emit("reptile.tile.similarProductsOpen", {
      variationId: this.variationId,
    });
  }
}

export default function registerSimilarProductButton() {
  eventQBus.on("ftfind.tile.loaded", (event: OnTileLoaded) =>
    SimilarProductsButton.create(event.target, event.variationId),
  );
  eventQBus.on("ftfind.tilelist.restored", () => SimilarProductsButton.createAll());
}
